import PropTypes from 'prop-types';
import { Image, Text } from '@/components/atoms';
import styles from './clothingSizesModal.module.scss';
import Tables from './Tables';

const info = [
  {
    highlight: 'PECHO',
    rest: 'para saber qué talle tenés, rodeá tus pectorales con una cinta métrica flexible en la parte de mayor volumen.'
  },
  {
    highlight: 'CINTURA',
    rest: 'rodeá la parte más estrecha de tu cintura con una cinta métrica.'
  },
  {
    highlight: 'CADERA',
    rest: 'parate con los pies juntos y rodea tus caderas con una cinta métrica en la parte de mayor volumen.'
  },
  {
    highlight: 'TIRO DE LA ENTREPIERNA',
    rest: 'medí la longitud de la pierna desde la ingle al tobillo.'
  }
];

const ClothingSizesModal = ({ gender = undefined }) => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.firstContent}>
        <Text
          colored
          textSize="s"
          textStyle={{ marginBottom: '1rem' }}
        >
          ¿Cómo tomar tus medidas?
        </Text>
        <div className={styles.imageReference}>
          <div className={styles.image}>
            <Image
              src="/assets/talle_indumentaria.svg"
              fill
            />
          </div>
          <div className={styles.indications}>
            {info.map((textInfo) => (
              <div style={{ marginBottom: '1rem' }}>
                <Text
                  colored
                  weight="bold"
                  textStyle={{ display: 'inline' }}
                >
                  {textInfo.highlight}{' '}
                </Text>
                <Text
                  textStyle={{ display: 'inline' }}
                  textColor="var(--color-white-absolute)"
                >
                  {textInfo.rest}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.tableBox}>
        <Tables gender={gender} />
      </div>
    </div>
  );
};

ClothingSizesModal.propTypes = {
  gender: PropTypes.string
};

export default ClothingSizesModal;
