import { Image } from '@/components/atoms';
import styles from './shoeSizesModal.module.scss';
import Text from '@/components/atoms/Text';

const ShoeSizesModal = () => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.firstContent}>
        <div className={styles.introduction}>
          <Text
            colored
            textSize="s"
            textStyle={{ marginBottom: '1rem' }}
          >
            ¿Cómo medir mi pie?
          </Text>
          <Text
            textColor="var(--color-white-absolute)"
            textStyle={{ marginBottom: '2rem' }}
          >
            Dibujá en una hoja de papel desde el talón a la punta del dedo gordo
            de tu pie, como indica la imagen de referencia. Luego uní esos dos
            puntos y te dará la medida en centímetros.
          </Text>
        </div>
        <div className={styles.imageReference}>
          <Image
            alt="showSizes"
            src="/assets/talle_calzado.svg"
            fill
          />
        </div>
      </div>
      <div className={styles.tableBox}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <th>Talle (ARG)</th>
              <th>Largo del pie</th>
              <th>Talle (USA)</th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            <tr>
              <td>34</td>
              <td>21.5 cm</td>
              <td>4.5</td>
            </tr>
            <tr>
              <td>35</td>
              <td>22 cm</td>
              <td>5.5</td>
            </tr>
            <tr>
              <td>36</td>
              <td>23 cm</td>
              <td>6</td>
            </tr>
            <tr>
              <td>37</td>
              <td>24 cm</td>
              <td>7</td>
            </tr>
            <tr>
              <td>38</td>
              <td>24.5 cm</td>
              <td>7.5</td>
            </tr>
            <tr>
              <td>39</td>
              <td>25.5 cm</td>
              <td>8.5</td>
            </tr>
            <tr>
              <td>40</td>
              <td>26 cm</td>
              <td>9</td>
            </tr>
            <tr>
              <td>41</td>
              <td>27 cm</td>
              <td>10</td>
            </tr>
            <tr>
              <td>42</td>
              <td>27.5 cm</td>
              <td>10.5</td>
            </tr>
            <tr>
              <td>43</td>
              <td>28 cm</td>
              <td>11</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ShoeSizesModal;
