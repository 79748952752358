/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import { Text } from '@/components/atoms';
import styles from './tables.module.scss';

const menTable = () => (
  <div className={styles.tableBox}>
    <Text
      colored
      textStyle={{ marginLeft: '1rem' }}
    >
      HOMBRE
    </Text>
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th>Talle</th>
          <th>Pecho</th>
          <th>Cintura</th>
          <th>Cadera</th>
          <th>Entrepierna</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        <tr>
          <td>XS</td>
          <td>82-87 cm</td>
          <td>71-75 cm</td>
          <td>82-86 cm</td>
          <td>81 cm</td>
        </tr>
        <tr>
          <td>S</td>
          <td>88-94 cm</td>
          <td>76-82 cm</td>
          <td>87-93 cm</td>
          <td>81.5 cm</td>
        </tr>
        <tr>
          <td>M</td>
          <td>95-102 cm</td>
          <td>83-90 cm</td>
          <td>94-101 cm</td>
          <td>82 cm</td>
        </tr>
        <tr>
          <td>L</td>
          <td>103-111 cm</td>
          <td>91-99 cm</td>
          <td>102-110 cm</td>
          <td>82.5 cm</td>
        </tr>
        <tr>
          <td>XL</td>
          <td>112-121 cm</td>
          <td>100-109 cm</td>
          <td>111-119 cm</td>
          <td>83 cm</td>
        </tr>
        <tr>
          <td>XXL</td>
          <td>122-132 cm</td>
          <td>110-121 cm</td>
          <td>120-128 cm</td>
          <td>83.5 cm</td>
        </tr>
        <tr>
          <td>XXXL</td>
          <td>133-144 cm</td>
          <td>122-134 cm</td>
          <td>129-138 cm</td>
          <td>84 cm</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const womenTable = () => (
  <div className={styles.tableBox}>
    <Text
      colored
      textStyle={{ marginLeft: '1rem' }}
    >
      MUJER
    </Text>
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th>Talle</th>
          <th>Pecho</th>
          <th>Cintura</th>
          <th>Cadera</th>
          <th>Entrepierna</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        <tr>
          <td>XS</td>
          <td>73-76 cm</td>
          <td>57-60 cm</td>
          <td>82-85 cm</td>
          <td>77.5 cm</td>
        </tr>
        <tr>
          <td>S</td>
          <td>77-82 cm</td>
          <td>61-66 cm</td>
          <td>86-91 cm</td>
          <td>78 cm</td>
        </tr>
        <tr>
          <td>M</td>
          <td>83-88 cm</td>
          <td>67-72 cm</td>
          <td>92-97 cm</td>
          <td>78.5 cm</td>
        </tr>
        <tr>
          <td>L</td>
          <td>89-94 cm</td>
          <td>73-78 cm</td>
          <td>98-103 cm</td>
          <td>79 cm</td>
        </tr>
        <tr>
          <td>XL</td>
          <td>95-101 cm</td>
          <td>79-85 cm</td>
          <td>104-110 cm</td>
          <td>79.5 cm</td>
        </tr>
        <tr>
          <td>XXL</td>
          <td>102-109 cm</td>
          <td>86-94 cm</td>
          <td>111-117 cm</td>
          <td>80 cm</td>
        </tr>
        <tr>
          <td>XXXL</td>
          <td>110-118 cm</td>
          <td>94-104 cm</td>
          <td>118-125 cm</td>
          <td>80.5 cm</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const childrenTable = () => (
  <div className={styles.tableBox}>
    <Text
      colored
      textStyle={{ marginLeft: '1rem' }}
    >
      NIÑO
    </Text>
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th>Talle</th>
          <th>Altura</th>
          <th>Pecho</th>
          <th>Cintura</th>
          <th>Cadera</th>
          <th>Entrepierna</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        <tr>
          <td>0 meses</td>
          <td>56-62 cm</td>
          <td>38.5-43 cm</td>
          <td>38.6-43 cm</td>
          <td>38.6-43 cm</td>
          <td>19.1 cm</td>
        </tr>
        <tr>
          <td>0-3 meses</td>
          <td>62-68 cm</td>
          <td>43-45.5 cm</td>
          <td>43-45 cm</td>
          <td>43-45 cm</td>
          <td>21.75 cm</td>
        </tr>
        <tr>
          <td>3-6 meses</td>
          <td>68-74 cm</td>
          <td>45.5-47 cm</td>
          <td>45-46.5 cm</td>
          <td>45-46.5 cm</td>
          <td>24.25 cm</td>
        </tr>
        <tr>
          <td>6-9 meses</td>
          <td>74-80 cm</td>
          <td>47-49.5 cm</td>
          <td>46.5-48.5 cm</td>
          <td>46.5-48.5 cm</td>
          <td>27 cm</td>
        </tr>
        <tr>
          <td>9-12 meses</td>
          <td>80-86 cm</td>
          <td>49.5-51 cm</td>
          <td>48.5-49.5 cm</td>
          <td>48.5-49.5 cm</td>
          <td>31.12 cm</td>
        </tr>
        <tr>
          <td>12-18 meses</td>
          <td>86 cm</td>
          <td>51-51.5 cm</td>
          <td>49.5-50 cm</td>
          <td>49.5-52 cm</td>
          <td>34.5 cm</td>
        </tr>
        <tr>
          <td>1-2 años</td>
          <td>86-92 cm</td>
          <td>51.5-52.5 cm</td>
          <td>50-50.5 cm</td>
          <td>52-53 cm</td>
          <td>38 cm</td>
        </tr>
        <tr>
          <td>2-3 años</td>
          <td>93-98 cm</td>
          <td>53-54.5 cm</td>
          <td>51-52.5 cm</td>
          <td>53.5-56 cm</td>
          <td>41 cm</td>
        </tr>
        <tr>
          <td>3-4 años</td>
          <td>99-104 cm</td>
          <td>55-56 cm</td>
          <td>53-54.5 cm</td>
          <td>56.5-60 cm</td>
          <td>45.5 cm</td>
        </tr>
        <tr>
          <td>5-6 años</td>
          <td>111-116 cm</td>
          <td>58.5-60.5 cm</td>
          <td>55.5-56 cm</td>
          <td>62.5-64 cm</td>
          <td>52 cm</td>
        </tr>
        <tr>
          <td>6-7 años</td>
          <td>117-122 cm</td>
          <td>61-63 cm</td>
          <td>56-57 cm</td>
          <td>64.5-66 cm</td>
          <td>56 cm</td>
        </tr>
        <tr>
          <td>7-8 años</td>
          <td>123-128 cm</td>
          <td>63.5-64 cm</td>
          <td>57.5-59 cm</td>
          <td>66.5-68 cm</td>
          <td>59 cm</td>
        </tr>
        <tr>
          <td>8-9 años</td>
          <td>129-134 cm</td>
          <td>64.5-67.5 cm</td>
          <td>59.5-61.5 cm</td>
          <td>68.5-71 cm</td>
          <td>62 cm</td>
        </tr>
        <tr>
          <td>9-10 años</td>
          <td>135-140 cm</td>
          <td>68-71 cm</td>
          <td>62-63.5 cm</td>
          <td>71.5-74.5 cm</td>
          <td>65 cm</td>
        </tr>
        <tr>
          <td>10-11 años</td>
          <td>141-146 cm</td>
          <td>71.5-74.5 cm</td>
          <td>64-66 cm</td>
          <td>75-77.5 cm</td>
          <td>67 cm</td>
        </tr>
        <tr>
          <td>11-12 años</td>
          <td>147-152 cm</td>
          <td>75-78 cm</td>
          <td>66.5-68 cm</td>
          <td>78-81 cm</td>
          <td>70 cm</td>
        </tr>
        <tr>
          <td>12-13 años</td>
          <td>153-158 cm</td>
          <td>78.5-82 cm</td>
          <td>68.5-70.5 cm</td>
          <td>81.5-85 cm</td>
          <td>73 cm</td>
        </tr>
        <tr>
          <td>13-14 años</td>
          <td>159-164 cm</td>
          <td>82.5-86 cm</td>
          <td>71-73 cm</td>
          <td>81.5-89 cm</td>
          <td>76 cm</td>
        </tr>
        <tr>
          <td>14-15 años</td>
          <td>165-170 cm</td>
          <td>86.5-89 cm</td>
          <td>73.5-74.5 cm</td>
          <td>89.5-91.5 cm</td>
          <td>79 cm</td>
        </tr>
        <tr>
          <td>15-16 años</td>
          <td>171-176 cm</td>
          <td>89.5-91.5 cm</td>
          <td>75-76.5 cm</td>
          <td>92-94 cm</td>
          <td>82 cm</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Tables = ({ gender = '' }) => {
  switch (gender) {
    case 'Hombre':
      return menTable();

    case 'Mujer':
      return womenTable();

    case 'Niño':
      return childrenTable();

    case 'Unisex':
      return (
        <>
          {menTable()} {womenTable()}
        </>
      );

    default:
      return <></>;
  }
};

Tables.propTypes = {
  gender: PropTypes.string
};

export default Tables;
