import React from 'react';
import PropTypes from 'prop-types';
import styles from './size-tooltip.module.scss';

const SizeTooltip = ({ visible, equivalenceTable }) => {
  if (!visible) return null;

  if (Object.keys(equivalenceTable).length === 0) return;

  return (
    <div className={styles.container}>
      {equivalenceTable?.ar_size && (
        <span className={styles.big}>{equivalenceTable?.ar_size}</span>
      )}
      {equivalenceTable?.eu_size && (
        <span
          className={styles.small}
        >{`EU ${equivalenceTable?.eu_size}`}</span>
      )}
      {equivalenceTable?.uk_size && (
        <span
          className={styles.small}
        >{`UK ${equivalenceTable?.uk_size}`}</span>
      )}
      {equivalenceTable?.us_size && (
        <span
          className={styles.small}
        >{`USA ${equivalenceTable?.us_size}`}</span>
      )}
      {equivalenceTable?.cm_size && (
        <span
          className={styles.small}
        >{`CM ${equivalenceTable?.cm_size}`}</span>
      )}
    </div>
  );
};

SizeTooltip.propTypes = {
  visible: PropTypes.bool.isRequired,
  equivalenceTable: PropTypes.object.isRequired
};

export default SizeTooltip;
