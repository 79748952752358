import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button, Icon, Text } from '@/components/atoms';
import styles from './sizesModal.module.scss';
import ShoeSizesModal from '@/components/molecules/ProductSheetCard/ProductInfo/SizeSelector/SizesModal/ShoeSizesModal';
import ClothingSizesModal from '@/components/molecules/ProductSheetCard/ProductInfo/SizeSelector/SizesModal/ClothingSizesModal';
import useOutsideClick from '@/hooks/useOutsideClick';
import useFixBody from '@/hooks/useFixBody';

const SizesModal = ({ setShowModal, business, gender = undefined }) => {
  const ref = useRef(null);
  useFixBody();
  useOutsideClick(ref, () => {
    setShowModal(false);
  });
  return (
    <div className={styles.backdrop}>
      <div
        ref={ref}
        className={styles.modal}
      >
        <div className={styles.title}>
          <div style={{ flex: 1 }}>
            <Text
              weight="bold"
              textSize="xl"
              textStyle={{
                textAlign: 'center'
              }}
              textColor="var(--color-white-absolute)"
            >
              Guía de Talles{` - ${gender}`}
            </Text>
          </div>
          <div style={{ width: 30, height: 30 }}>
            <Button
              withOutPadding
              variant="icon"
              startIcon={
                <Icon
                  type="close"
                  color="white"
                  width="1rem"
                />
              }
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
        {business === 'Calzado' && <ShoeSizesModal />}
        {business === 'Indumentaria' && <ClothingSizesModal gender={gender} />}
      </div>
    </div>
  );
};

SizesModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  business: PropTypes.string.isRequired,
  gender: PropTypes.string
};

export default SizesModal;
