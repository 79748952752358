const scroll = (elementRefTo, isDesktop = false, position = 'after') => {
  if (typeof document !== 'undefined' && elementRefTo !== null) {
    const refTo = document.getElementById(elementRefTo);

    window.scrollTo({
      top:
        refTo.offsetTop + (isDesktop ? -400 : 100) ||
        0 + (position === 'before', refTo.offsetHeight * 2 || 0),
      behavior: 'smooth'
    });
  }
};

export default scroll;
