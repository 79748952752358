'use client';

/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import { usePathname } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Divider, Text } from '@/components/atoms';
import SizeItem from '@/components/atoms/SizeItem';
import { setVariant } from '@/redux/productDetail/productDetailReducer';
import styles from './sizeSelector.module.scss';
import SizesModal from '@/components/molecules/ProductSheetCard/ProductInfo/SizeSelector/SizesModal';
import scroll from '@/utils/scroll';
import useDeviceType from '@/hooks/useDeviceType';

const SizeSelector = ({
  business,
  onClick,
  variantSelected = {},
  variants = [],
  gender = undefined,
  isModal = false
}) => {
  const dispatch = useDispatch();
  const { isDesktop } = useDeviceType();
  const pathname = usePathname();
  const storeId = useSelector((state) => state.store);
  const handleClickSize = (newVariantSelected) => {
    onClick({ available: true, path: pathname });
    dispatch(setVariant(newVariantSelected));
    !isModal && scroll('AddToCart', isDesktop);
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className={styles.container}
      id="size-selector"
    >
      <div className={styles.flex}>
        <div style={{ display: 'flex', gap: '.7rem' }}>
          <Text
            variant="h2"
            textSize="m"
            weight="light"
          >
            Talle:
          </Text>
          {variantSelected?.size && (
            <Text
              variant="span"
              textSize="m"
              weight="bold"
            >
              {variantSelected.size}
            </Text>
          )}
        </div>
        {(business === 'Calzado' || business === 'Indumentaria') && (
          <div>
            <Button
              id="show size Modal"
              label="¿Cuál es mi talle?"
              textColor="var(--color-primary)"
              style={{ alignItems: 'flex-start', height: 'auto' }}
              withOutPadding
              textStyle={{ fontSize: '.875rem' }}
              onClick={() => setShowModal(true)}
            />
          </div>
        )}
      </div>
      {!storeId && (
        <Divider
          style={{ marginBottom: '1rem' }}
          color="#707070"
        />
      )}
      <ul className={styles.list}>
        {variants.map((variant) => {
          return (
            <SizeItem
              key={variant.id}
              size={variant.size.replace('AÑOS', ' AÑOS')}
              isSelected={variant.id === variantSelected?.id}
              onClick={() => handleClickSize(variant)}
              noCount={variant.count_on_hand < 1}
              equivalenceTable={variant.equivalence_table}
            />
          );
        })}
      </ul>
      {showModal && (
        <SizesModal
          setShowModal={setShowModal}
          business={business}
          gender={gender}
        />
      )}
    </div>
  );
};

SizeSelector.propTypes = {
  variantSelected: PropTypes.object,
  variants: PropTypes.array,
  business: PropTypes.string.isRequired,
  gender: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isModal: PropTypes.bool
};

export default SizeSelector;
