import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  slug: null,
  product: null,
  colorSelected: null,
  variantSelected: null,
  quantity: 0,
  error: null,
  idColor: null,
  openModalWhearItWith: false,
  images: [],
  price: '',
  embeddeds: {},
  colors: []
};

const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    setProductSelected(state, action) {
      return { ...state, ...action.payload };
    },
    setVariant(state, action) {
      state.variantSelected = action.payload;
    },
    setQuantity(state, action) {
      state.quantity = action.payload;
    },
    setOpenModalWhearItWith(state, action) {
      state.openModalWhearItWith = action.payload;
    }
  }
});

export const {
  setProductSelected,
  setVariant,
  setQuantity,
  setOpenModalWhearItWith
} = productDetailSlice.actions;
export default productDetailSlice.reducer;
